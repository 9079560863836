export const environment = {
  production: true,
  enableDebug: false,
  env: "PROD",
  appVersion: "1.18.35-master-ab06b427",
  apiUrl: "https://api-v1.eva-it.ch/api",
  googleAnalyticsId: "G-R0LN5PCTR8",
  hotjarId: null,
  clarityId: "jyipvwjxfv",
  intercomId: "jdgxwp9j",
  sfLicense: "Ngo9BigBOggjHTQxAR8/V1NGaF1cWGhIfEx1RHxQdld5ZFRHallYTnNWUj0eQnxTdEZjUX5acHdURWFaUkFyWw==",
};
